
//    Font Family

// $primary-font	: 'Poppins', sans-serif;
$primary-font	: 'Lato', sans-serif;

/*--------------------------- Color variations ----------------------*/

$primary-color	:   #88A1B3;
$serondary-color	: #88d200;
$title-color	: #222222;
$text-color		: #777777;

$white			: #fff;
$offwhite		: rgb(245, 245, 250);
$black			: #222;






